













































import { Component, Vue } from 'vue-property-decorator';
import GoalsUndefinedViewModel
  from '@/vue-app/view-models/allianz-dashboard/goals/goals-undefined-view-model';

@Component({
  name: 'GoalsUndefinedCard',
  components: {
    FlagshipGoalsModal: () => import('@/vue-app/components/flagship/flagship-goals/FlagshipGoalsModal.vue'),
    FlagshipGoalsWizardModal: () => import('@/vue-app/components/flagship/flagship-goals/FlagshipGoalsWizardModal.vue'),
  },
})
export default class GoalsUndefinedCard extends Vue {
  goals_undefined_view_model = Vue.observable(new GoalsUndefinedViewModel());

  created() {
    this.goals_undefined_view_model.initialize();
  }
}
